import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import StreetCulture from "./projects/StreetCulture";
import MotionVideo from "./projects/MotionVideo";
import EwgRedesign from "./projects/EwgRedesign";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects/1" element={<EwgRedesign />} />
      <Route path="/projects/3" element={<MotionVideo />} />
    </Routes>
  );
};

export default App;
