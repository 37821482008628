import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import motionvideo from "../images/motion-video.mp4";

const MotionVideo = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <section>
        <a onClick={handleBack} id="back-btn">
          Back
        </a>
        <div>
          <h1>RM Tee Product Launch</h1>
          {/* <p>xxx</p> */}

          <video
            ref={videoRef}
            src={motionvideo}
            autoPlay
            loop
            preload="metadata"
            playsInline
            className="motion-video"
            onClick={togglePlayPause}
          />
        </div>
      </section>
    </>
  );
};

export default MotionVideo;
