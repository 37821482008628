import React from "react";
import { useNavigate } from "react-router-dom";
import ewgarticles from "../images/ewg-articles-pr.png";
import ewgdonate from "../images/ewg-donate-pr.png";
import ewglanding from "../images/ewg-landing-pr.png";
import ewgnews from "../images/ewg-news-pr.png";
import ewgtraffic from "../images/ewg-traffics-pr.png";

const EwgRedesign = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <section className="ewg-redesign-section">
        <a onClick={handleBack} id="back-btn">
          Back
        </a>
        <div>
          <h1>EWG Websie Redesign</h1>
          <p>
            When redesigning the website the focus was largely drawn on the
            transitions between the sections, improving the User Experience and
            tone of the website and just easier navigation throughout the
            website overall I achieved this by using many illustrations when
            transitioning between sections, splitting or combining existing
            sections, such as splitting the Consumer Guides section into tools
            and informative articles, referencing to the website traffic (link),
            using a more friendly font to lighten up the tone of the website,
            used a small colour palette to create a coherent design, used font
            sizes to create a clearer visual hierarchy, shift focus and more.
          </p>
          <p></p>
          <div className="project-imgs">
            <img src={ewglanding} alt="ewg landing" />
            <img src={ewgarticles} alt="ewg articles" />
            <img src={ewgdonate} alt="ewg donate" />
            <img src={ewgnews} alt="ewg news" />
            <p>
              Consumer Guides on EWG’s website provides informative articles and
              websites which act as tools for users to search for EWG verified
              products. I split these two into two different sections for easier
              User Experience interaction.
            </p>
            <img src={ewgtraffic} alt="ewg traffic" id="traffic-img" />
            <p>
              For example, a lot of the organic user traffic goes to the keyword
              “ewg skin deep”, though on their website, especially mobile
              version, it is in their fourth slide, by providing all of the
              tools in a single section, improves the user navigation and may
              even draw more attention and traffic to the other less known tools
              that EWG provides.
            </p>
          </div>
          {/* 
          title
          desc / introduction
          date
          app used
           */}

          {/* 
          design process
          inspo 
          */}
        </div>
      </section>
    </>
  );
};

export default EwgRedesign;
